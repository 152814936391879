import { WebsiteNotFoundError, ContentNotFoundError, isWebsiteNotFoundResponseData } from "./../utils/errors";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { $httpEnterpriseVacanAdapter } from "@/api/http";
import { Vacancy, Ticket, Http, Place } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import { getLocaleForAPI } from "@/plugins/i18n";

import store from "@/store"; // vuex store
const ConnectionError = { message: "connection error", name: "" };

/**
 * display network error pop up with reload button
 */
function displayNetworkAlertErrorView(status = true): void {
    store.commit("setAlertView", {
        status,
    });
}

export function fetchPlaceListByWebsiteId(websiteId: string): Promise<Http.ResponseGetPlacesInWebsite | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseGetPlacesInWebsite>(`/v1/websites/${websiteId}`, {
            params: { namespace, language: getLocaleForAPI() },
        })
        .then((response) => {
            displayNetworkAlertErrorView(false);
            return response.status === 204 ? undefined : response.data;
        })
        .catch((error) => {
            displayNetworkAlertErrorView();
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export async function fetchPlaceDetailByPlaceIdHash(
    websiteId: string,
    placeIdHash: string,
): Promise<Place.PlaceDetail> {
    return await $httpEnterpriseVacanAdapter
        .get<Place.PlaceDetail>(`/v1/websites/${websiteId}/places/${placeIdHash}`, {
            params: { language: getLocaleForAPI(), unixtime: dayjs().unix() },
        })
        .then((response) => {
            displayNetworkAlertErrorView(false);
            return response.data;
        })
        .catch((error) => {
            if (error.response?.status === 404 && isWebsiteNotFoundResponseData(error.response.data)) {
                throw new WebsiteNotFoundError();
            }

            if (400 <= error.response?.status && error.response?.status < 500) {
                throw new ContentNotFoundError();
            }
            displayNetworkAlertErrorView();
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchResponseHotSpringValuesByPlaceIdHashList(
    placeIdHashList: string[],
): Promise<Http.ResponseHotSpringValues[] | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseHotSpringValues[]>(`/v1/places/${placeIdHashList.join(",")}/hotspring/settings`, {
            params: { namespace, language: getLocaleForAPI() },
        })
        .then((response) => (response.status === 204 ? undefined : response.data))
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchVacancyListByPlaceIdHashList(placeIdHashList: string[]): Promise<Vacancy.Vacancy[]> {
    const params: Record<string, string> = { language: getLocaleForAPI() };

    return $httpEnterpriseVacanAdapter
        .get<Vacancy.Vacancy[]>(`/v1/places/${placeIdHashList.join(",")}/vacancies`, { params })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchVacancyStateThresholdListByPlaceIdHashList(
    placeIdHashList: string[],
): Promise<Http.ResponseVacancyStateThreshold> {
    return $httpEnterpriseVacanAdapter
        .get<Vacancy.VacancyStateThreshold[]>(`/v1/places/${placeIdHashList.join(",")}/vacancies/thresholds`)
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchWeekdayList(unixtime = dayjs().unix(), countryName = "jp"): Promise<Http.ResponseWeekdayList> {
    const params = { unixtime };
    return $httpEnterpriseVacanAdapter
        .get(`/v1/weeks/${countryName}`, { params })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchForecast(placeIdHashList: string[], unixtime: number): Promise<Http.ResponseForecast> {
    const params = { unixtime };
    return $httpEnterpriseVacanAdapter
        .get(`/v1/places/${placeIdHashList.join(",")}/forecast`, { params })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchVacancyHistory(placeIdHashList: string[]): Promise<Http.ResponseVacancyHistory> {
    return $httpEnterpriseVacanAdapter
        .get(`/v1/places/${placeIdHashList.join(",")}/vacancies/histories`)
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchTicketConfigByPlaceIdHash(placeIdHash: string): Promise<Ticket.TicketConfig> {
    const params: Record<string, string> = { language: getLocaleForAPI() };

    return $httpEnterpriseVacanAdapter
        .get<Ticket.TicketConfig>(`/v1/places/${placeIdHash}/tickets/config`, { params })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchTicketConfigByTicketIdHash(ticketIdHash: string): Promise<Ticket.TicketConfig> {
    const params: Record<string, string> = { language: getLocaleForAPI() };

    return $httpEnterpriseVacanAdapter
        .get<Ticket.TicketConfig>(`/v1/tickets/${ticketIdHash}/config`, { params })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function sendPreRequest(
    placeIdHash: string,
    request: Http.RequestPostPlacesTicketsPreRequest,
): Promise<Http.ResponsePostPlacesTicketsPreRequest> {
    return $httpEnterpriseVacanAdapter
        .post(`/v1/places/${placeIdHash}/tickets/prerequest`, {
            ...request,
            locale: request.locale || getLocaleForAPI(),
        })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.status : 0));
}

export function activateTicket(requestIdHash: string): Promise<Http.ResponsePostTickets> {
    const request: Http.RequestPostTickets = { requestIdHash, locale: getLocaleForAPI() };

    return $httpEnterpriseVacanAdapter
        .post<Http.RequestPostTickets, AxiosResponse<Http.ResponsePostTickets>>(`/v1/tickets`, request)
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchTicketByTicketIdHash(ticketIdHash: string): Promise<Http.ResponseGetTickets> {
    const params: Record<string, string> = { language: getLocaleForAPI() };

    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseGetTickets>(`/v1/tickets/${ticketIdHash}`, { params })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function checkInTicket(ticketIdHash: string): Promise<Http.ResponsePutTickets> {
    const request: Http.RequestPutTickets = { operationType: "checkIn" };
    const params: Record<string, string> = { language: getLocaleForAPI() };

    return $httpEnterpriseVacanAdapter
        .put<Http.RequestPutTickets, AxiosResponse<Http.ResponsePutTickets>>(`/v1/tickets/${ticketIdHash}`, request, {
            params,
        })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function cancelTicket(ticketIdHash: string): Promise<Http.ResponsePutTickets> {
    const request: Http.RequestPutTickets = { operationType: "cancel" };
    const params: Record<string, string> = { language: getLocaleForAPI() };

    return $httpEnterpriseVacanAdapter
        .put<Http.RequestPutTickets, AxiosResponse<Http.ResponsePutTickets>>(`/v1/tickets/${ticketIdHash}`, request, {
            params,
        })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function sendNotificationPreRequest(
    ticketIdHash: string,
    email: string,
    activateUrl: string,
    locale: Http.TicketMessageLocale,
): Promise<number> {
    const request: Http.RequestPostTicketsPreRequestNotification = { email, activateUrl, locale };

    return $httpEnterpriseVacanAdapter
        .post<Http.RequestPostTicketsPreRequestNotification>(
            `/v1/tickets/${ticketIdHash}/prerequest/notifications`,
            request,
        )
        .then((response) => response.status)
        .catch((error) => Promise.reject(error.response ? error.response.status : 0));
}

export function verifyNotification(activationHash: string): Promise<Http.ResponsePutTicketsNotificationsActivate> {
    return $httpEnterpriseVacanAdapter
        .put<Http.RequestPutTicketsNotificationsActivate, AxiosResponse<Http.ResponsePutTicketsNotificationsActivate>>(
            `/v1/tickets/notifications/activate`,
            {
                activationHash,
            },
        )
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchPlaceFilterOptions(websiteId: string): Promise<Http.ResponseGetPlaceFilterOptions | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseGetPlaceFilterOptions>(`/v1/filter-options/websites/${websiteId}`, {
            params: { namespace, language: getLocaleForAPI() },
        })
        .then((response) => (response.status === 204 ? undefined : response.data))
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchJrWestVacancyPrediction(point: number): Promise<Http.ResponseJrWestPrediction | undefined> {
    const params = point > 1 ? { point: point } : undefined;

    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseJrWestPrediction>(`/v1/place/jr-west/vacancy-prediction`, { params })
        .then((response) => (response.status === 204 ? undefined : response.data))
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

// FIXME: BFF側の公開しているインタフェースと返しているプロパティが違っている
interface ProfileResponse {
    prTitle?: string;
    prText?: string;
    canTakeout: boolean;
    takeoutUrl?: string;
    accessInformation: string;
    nearbyLandmark: string;
}

interface PlaceWihtProfile extends Place.Place {
    profile?: ProfileResponse;
}

interface ResponseGetPlacesInWebsiteWithQuickSurvey {
    websiteTitle: { name: string };
    questionnaireUrl?: string;
    placeList: PlaceWihtProfile[];
    filtering: boolean;
    theme?: string;
    isAvailable: boolean;
    showQuickSurvey: boolean;
    layoutId: number;
    relatedItemList: Http.RelatedItem[];
}

export function fetchFilteredPlaceListByWebsiteId(
    websiteId: string,
    offset?: number,
    limit?: number,
    categoryIdList?: number[],
    floorList?: string[],
    areaIdList?: number[],
    featureList?: string[],
    vacancyList?: string[],
): Promise<ResponseGetPlacesInWebsiteWithQuickSurvey | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<ResponseGetPlacesInWebsiteWithQuickSurvey>(`/v1/websites/${websiteId}`, {
            params: {
                namespace,
                language: getLocaleForAPI(),
                offset,
                limit,
                categoryIdList: categoryIdList?.join(",") || undefined,
                floorList: floorList?.join(",") || undefined,
                areaIdList: areaIdList?.join(",") || undefined,
                featureList: featureList?.join(",") || undefined,
                vacancyList: vacancyList?.join(",") || undefined,
            },
        })
        .then((response) => {
            displayNetworkAlertErrorView(false);
            return response.status === 204 ? undefined : response.data;
        })
        .catch((error) => {
            displayNetworkAlertErrorView();
            if (error.response?.status === 500) {
                return undefined;
            } else if (400 <= error.response?.status && error.response?.status < 500) {
                throw new WebsiteNotFoundError();
            }

            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchUpdatedFilters(
    placeIdHashList: string[],
): Promise<Http.ResponseGetPlaceFilterOptions | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseGetPlaceFilterOptions>(`/v1/filter-options/place/${placeIdHashList.join(",")}`, {
            params: { namespace, language: getLocaleForAPI() },
        })
        .then((response) => (response.status === 204 ? undefined : response.data))
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export interface QuickSurveySetting {
    enabled: boolean;
}

export interface QuickSurveyResponse {
    websiteId: string;
    responseIndex: number;
    language: string;
}

export function postQuickSurveyAnswer(response: QuickSurveyResponse): Promise<void> {
    return $httpEnterpriseVacanAdapter
        .post(`/v1/quick-survey/response`, response)
        .then(() => undefined)
        .catch((error) => {
            return Promise.reject(error.response ? error.response : ConnectionError);
        });
}
