
import Vue from "vue";

import {
  Vacancy as BackendVacancy,
  Place as BackendPlace,
  Http,
  Ticket,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import {
  fetchVacancyListByPlaceIdHashList,
  fetchResponseHotSpringValuesByPlaceIdHashList,
  fetchTicketConfigByPlaceIdHash,
} from "@/api/enterprise-vacan.adapter.api";

import PlacePanelImage from "@/components/place/panel/image.vue";
import PlacePanelSummary from "@/components/place/panel/summary.vue";
import PlacePanelBasic from "@/components/place/panel/basic.vue";
import PlacePanelMedia from "@/components/place/panel/media.vue";
import Questionnaire from "@/components/questionnaire.vue";
import QuickSurvey from "@/components/quick-survey.vue";
import { mapGetters } from "vuex";
import { LayoutType } from "@/utils/layout";

interface ProfileResponse {
  prTitle?: string;
  prText?: string;
  canTakeout: boolean;
  takeoutUrl?: string;
  accessInformation: string;
  nearbyLandmark: string;
}

interface PlaceDetail extends BackendPlace.PlaceDetail {
  profile?: ProfileResponse;
}

interface Profile {
  title?: string;
  body?: string;
  togo: boolean;
  togoUrl?: string;
  direction: string;
  landmark: string;
}

type Vacancy = BackendVacancy.Vacancy;
type HotSpringSetting = Http.ResponseHotSpringValues;

interface DataViewsPlaces {
  placeDetail: PlaceDetail | undefined;
  vacancy: Vacancy | undefined;
  hotSpringSetting: HotSpringSetting | undefined;
  qticketConfig?: Ticket.TicketConfig;
  timer: { fetchingVacancies: number | undefined };
  pageStatus: "ok" | "preparing" | "error";
  lastFetchedVacancyUnixTime: number;
}

export default Vue.extend({
  name: "single-place-index",
  metaInfo(): { title: string; titleTemplate: string } {
    const placeName = this.placeDetail?.name;
    return {
      title: placeName ?? "",
      titleTemplate: this.$i18n.t("baseTitle").toString(),
    };
  },
  components: {
    PlacePanelImage,
    PlacePanelSummary,
    PlacePanelBasic,
    PlacePanelMedia,
    Questionnaire,
    QuickSurvey,
  },
  props: {
    placeIdHash: { type: String, required: true, default: undefined },
    websiteId: { type: String, required: true, default: undefined },
    questionnaireUrl: { type: String, required: false, default: undefined },
    showQuickSurvey: { type: Boolean, required: false, default: false },
    theme: { type: String, required: true, default: "white" },
  },
  data(): DataViewsPlaces {
    return {
      placeDetail: undefined,
      vacancy: undefined,
      hotSpringSetting: undefined,
      qticketConfig: undefined,
      timer: { fetchingVacancies: undefined },
      pageStatus: "preparing",
      lastFetchedVacancyUnixTime: new Date().getTime() / 1000,
    };
  },
  computed: {
    ...mapGetters({
      forecastState: "getForecast",
      layoutType: "getLayoutType",
    }),
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.currentGender;
    },
    showBusinessHour(): boolean {
      return this.placeDetail?.displaySetting?.isDisplayBusinessHour ?? true;
    },
    showRegularHoliday(): boolean {
      return this.placeDetail?.displaySetting?.isDisplayRegularHoliday ?? true;
    },
    showContactMessage(): boolean {
      return this.placeDetail?.displaySetting?.isDisplayContactMessage ?? true;
    },
    profile(): Profile | undefined {
      if (this.placeDetail?.profile === undefined) {
        return undefined;
      }
      return {
        title: this.placeDetail.profile.prTitle,
        body: this.placeDetail.profile.prText,
        togo: this.placeDetail.profile.canTakeout,
        togoUrl: this.placeDetail.profile.takeoutUrl,
        direction: this.placeDetail.profile.accessInformation,
        landmark: this.placeDetail.profile.nearbyLandmark,
      };
    },
    showQuestionnaire(): boolean {
      return this.layoutType === LayoutType.Default && !!this.questionnaireUrl;
    },
  },
  async mounted() {
    this.setup();
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    async setup(): Promise<void> {
      if (this.placeIdHash === undefined) {
        return;
      }
      this.pageStatus = "preparing";

      const promise = this.placeDetail
        ? Promise.resolve()
        : this.$store
            .dispatch("fetchPlaceDetail", { websiteId: this.websiteId, placeIdHash: this.placeIdHash })
            .then(() => {
              this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);
            });

      promise
        .then(async () => {
          this.pageStatus = "ok";
          await this.fetchHotSrpingSetting();
          await this.fetchVacancy();
          if (this.forecastState.enabledPlaceList.length > 0) {
            this.$store.dispatch("fetchWeekdayList");
          }
          this.loop();
        })
        .catch(() => {
          this.pageStatus = "error";
        });
    },
    loop(): void {
      this.timer.fetchingVacancies = window.setTimeout(async () => {
        await this.fetchVacancy().catch(() => {
          this.pageStatus = "error";
          this.teardown();
        });
        if (this.forecastState.enabledPlaceList.length > 0) {
          this.$store.dispatch("fetchWeekdayList");
        }
        this.loop();
      }, 5000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearTimeout(this.timer.fetchingVacancies);
      }
    },
    async fetchVacancy(): Promise<void> {
      try {
        const vacancyList: Vacancy[] = await fetchVacancyListByPlaceIdHashList([this.placeIdHash]);
        this.lastFetchedVacancyUnixTime = new Date().getTime();
        this.$store.commit("setAlertView", {
          status: false,
        });

        const vacancy: Vacancy | undefined = vacancyList.shift();
        if (vacancy !== undefined) {
          this.vacancy = vacancy;
        }
        if (this.placeDetail?.featureList.includes("qticket")) {
          this.qticketConfig = await fetchTicketConfigByPlaceIdHash(this.placeIdHash);
        }
      } catch {
        const NETWORK_ERROR_THRESHOLD = 3 * 60 * 1000;
        const currentUnixTime = new Date().getTime();

        if (currentUnixTime - NETWORK_ERROR_THRESHOLD >= this.lastFetchedVacancyUnixTime) {
          this.$store.commit("setAlertView", {
            status: true,
          });
        }
      }
    },
    async fetchHotSrpingSetting(): Promise<void> {
      const hotSpringSettingList = await fetchResponseHotSpringValuesByPlaceIdHashList([this.placeIdHash]);
      const hotSpringSetting: HotSpringSetting | undefined = hotSpringSettingList?.shift();
      if (hotSpringSetting !== undefined) {
        this.hotSpringSetting = hotSpringSetting;
      }
    },
  },
});
